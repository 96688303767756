import {Link, graphql} from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet'

import Layout from './layout';

export default function BlogIndex(props) {
  const lang = props.pageContext.language;
  const blog_items = props.data.allBlogYaml.edges.map(edge => edge.node);

  const node_data = {
    fields_en: {
      slug: '/blog-en',
      meta_description: 'Blog – records and posts of the actual photo stories. Professional photographer offers photo shooting for different occasions.',
    },
    fields_sl: {
      slug: '/blog',
      meta_description: 'Blog - zapisi in objave aktualnih fotografskih zgodb. Profesionalni fotograf Andraž Gregorič, ki ponuja fotografiranje za vse priložnosti.',
    },
  };

  return (
    <Layout language={lang} nodeData={node_data}>
      <Helmet>
        <title>Andraž Gregorič - {lang === 'sl' ? 'Blog' : 'Photography Blog'}</title>
      </Helmet>
      <div className='main-content blog-index'>
        <h1>{lang === 'sl' ? 'Profesionalno fotografiranje' : 'Professional Photography'}</h1>
        {blog_items.map(blog => {
          const fields = blog[`fields_${lang}`];
          const html = blog.fields[`html_${lang}`];
          const start_of_paragraph_idx = html.indexOf('<p>');
          const end_of_paragraph_idx = html.indexOf('</p>');
          const excerpt = start_of_paragraph_idx === -1 ?
                null :
                html.slice(start_of_paragraph_idx + 3, end_of_paragraph_idx);
          let show_post_label;
          if (lang === 'sl') {
            show_post_label = excerpt ? 'Preberi več' : 'Poglej objavo';
          } else {
            show_post_label = excerpt ? 'Read more' : 'Open post';
          }
          return (
            <div className='blog-item' key={blog.unique_title}>
              <h2><Link to={fields.slug}>{fields.nav_title || fields.title}</Link></h2>

              <Link to={fields.slug}>
                <img src={blog.title_image_src} alt={fields.title} />
              </Link>

              <p>
                {excerpt ? <span dangerouslySetInnerHTML={{__html: excerpt}} /> : null }
                <Link to={fields.slug} className='read-more'>{show_post_label}&hellip;</Link>
              </p>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query BlogQuery {
    allBlogYaml(sort: {fields: created_at, order: DESC}) {
      edges {
        node {
          unique_title
          title_image_src
          fields {
            html_sl
            html_en
          }
          fields_sl {
            slug
            title
            nav_title
          }
          fields_en {
            slug
            title
            nav_title
          }
        }
      }
    }
  }
`;
